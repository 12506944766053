import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Spin from '../views/Spin.vue'
import Login from '../views/Login.vue'
import Rewards from '../views/Rewards.vue'
import Promotion from '../views/Promotion.vue'
import Home from '../views/Home.vue'
import Play from '../views/Play.vue'
import PlayGame from '../views/PlayGame.vue'
import PlaySport from '../views/PlaySport.vue'
import PlayHuay from '../views/PlayHuay.vue'
import Gift from '../views/Gift.vue'
import Boxs from '../views/Boxs.vue'
import Register from '../views/Register.vue'
import History from '../views/History.vue'
import Roulet from '../views/Roulet.vue'
import Partner from '../views/Partner.vue'
import Continue from '../views/Continue.vue'
import Cashback from '../views/Cashback.vue'
import Event from '../views/Event.vue'
import Scratch from '../views/Scratch.vue'
import Main from '../views/Main.vue'
import HackSlot from '../views/HackSlot.vue'
import HackSlotSuccess from '../views/HackSlotSuccess.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/play',
    name: 'Play',
    component: Play,
  },
  {
    path: '/playgame',
    name: 'PlayGame',
    component: PlayGame,
  },
  {
    path: '/playsport',
    name: 'Playsport',
    component: PlaySport,
  },
  {
    path: '/playhuay',
    name: 'Playhuay',
    component: PlayHuay,
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: Promotion,
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/partner',
    name: 'partner',
    component: Partner,
    meta: {
      auth: true
    }
  },
  {
    path: '/cashback',
    name: 'cashback',
    component: Cashback,
    meta: {
      auth: true
    }
  },
  {
    path: '/continue',
    name: 'continue',
    component: Continue,
    meta: {
      auth: true
    }
  },
  {
    path: '/rewards',
    name: 'rewards',
    component: Rewards
  },
  {
    path: '/gift',
    name: 'gift',
    component: Gift
  },
  {
    path: '/boxs',
    name: 'boxs',
    component: Boxs
  },
  {
    path: '/scratch',
    name: 'scratch',
    component: Scratch
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/spin',
    name: 'spin',
    component: Spin,
  },
  {
    path: '/hackslot',
    name: 'hackslot',
    component: HackSlot
  },
  {
    path: '/hackslotsuccess',
    name: 'hackslotsuccess',
    component: HackSlotSuccess
  },
  {
    path: '/history',
    name: 'history',
    component: History,
  },
  {
    path: '/roulet',
    name: 'roulet',
    component: Roulet,
  },
  {
    path: '/event',
    name: 'event',
    component: Event,
  },
  {
    path: '/main/:type',
    name: 'main',
    component: Main,
  },
  {
    path: '/slot',
    name: 'slot',
    component: Main,
  },
  {
    path: '/casino',
    name: 'casino',
    component: Main,
  },
  {
    path: '/casino/:provider',
    name: 'casinoName',
    component: Main,
  },
  {
    path: '/sport',
    name: 'sport',
    component: Main,
  },
  {
    path: '/hotgames',
    name: 'hotgames',
    component: Main,
  },
  {
    path: '/newgames',
    name: 'newgames',
    component: Main,
  },
  {
    path: '/fish',
    name: 'fish',
    component: Main,
  },
  {
    path: '/fish/:provider',
    name: 'fishName',
    component: Main,
  },
  {
    path: '/provider',
    name: 'provider',
    component: Main,
  },
  {
    path: '/provider/:provider',
    name: 'providerName',
    component: Main,
  },
  {
    path: '/favorite',
    name: 'favorite',
    component: Main,
  },
  {
    path: '/card',
    name: 'card',
    component: Main,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (!firebase.auth().currentUser) {
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
